import React from "react"
import PageLayout from "../components/page-layout"

import Button from 'react-bootstrap/Button'

import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'

import Photo01 from "../images/studio-photo01.jpg"
import Photo02 from "../images/studio-photo02.jpg"
import Photo03 from "../images/studio-photo03.jpg"
import Photo04 from "../images/studio-photo04.jpg"
import Photo05 from "../images/studio-photo05.jpg"
import Photo06 from "../images/studio-photo06.jpg"
import Photo07 from "../images/studio-photo07.jpg"
import PDF from "../files/yukimamiya-calendar-2024.pdf"

export default function Home() {
    const pictures = [
        <div className="item" data-value="1"><img className="photo" src={Photo01} alt="photo01" /></div>,
        <div className="item" data-value="2"><img className="photo" src={Photo02} alt="photo02" /></div>,
        <div className="item" data-value="3"><img className="photo" src={Photo03} alt="photo03" /></div>,
        <div className="item" data-value="4"><img className="photo" src={Photo04} alt="photo04" /></div>,
        <div className="item" data-value="5"><img className="photo" src={Photo05} alt="photo05" /></div>,
        <div className="item" data-value="6"><img className="photo" src={Photo06} alt="photo06" /></div>,
        <div className="item" data-value="7"><img className="photo" src={Photo07} alt="photo07" /></div>,
    ];

    return (
        <PageLayout title="アクセス・スタジオ情報">
            <div class="company-info">
                <AliceCarousel
                    autoPlay
                    autoPlayInterval="1000"
                    animationType="fadeout"
                    animationDuration={800}
                    infinite
                    items={pictures}
                    mouseTracking
                    disableButtonsControls
                />
                <Button variant="outline-dark" className="business-day-calendar" rel="noopener noreferrer" href={PDF} target="_blank">年間営業日カレンダー</Button>
                <div className="googlemap">
                    <iframe title="yukimamiya map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3243.4183413787146!2d139.6735807158069!3d35.61741204107574!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6018f4deab1c11af%3A0xb02c94c5165f36d8!2z44CSMTUyLTAwMzEg5p2x5Lqs6YO955uu6buS5Yy65Lit5qC577yR5LiB55uu77yT4oiS77yZ!5e0!3m2!1sja!2sjp!4v1615632087810!5m2!1sja!2sjp" width="320" height="300"></iframe>
                </div>
            </div>
            <p>
                〒152-0031<br />
                東京都目黒区中根１丁目３−９<br />
                森戸ビル２階
            </p>

            <div className="traffic">
                <ul>
                    <li>東急東横線都立大学駅より徒歩１分</li>
                    <li>都立大学駅北口バス停より徒歩２分</li>
                    <li>東急都立大学駅（西）駐輪場よりすぐ</li>
                </ul>
                <p>※深沢・駒沢方面、目黒・白金方面からは、バスが便利です。<br />
                    ※羽田空港、東京駅、新横浜駅〜都立大学駅北口の直通バスもございます。<br />
                    ※バスの路線情報については<a href="https://transfer.navitime.biz/tokyubus/pc/diagram/BusCourseSearch?busstopId=00240491" target="_blank" rel="noreferrer">
                        東急バス公式サイト
                    </a>をご覧ください。
                </p>
            </div>

            <div className="inquiry">
                <Button variant="outline-dark" href="/inquiry" size="lg">お問合せ</Button>
            </div>
        </PageLayout >
    )
}